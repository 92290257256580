var cbtl = window.cbtl || {};

cbtl.headerColor = {
  init: function() {

    $(window).on('scroll', function() {
      if ($(window).scrollTop() > 0) {
        $('header').removeClass('header--home');
      } else {
        $('header').addClass('header--home');
      }
    });

  }
};

// Toggle mobile navigation modal
cbtl.mobileNav = {
  init: function() {
    $('#mobile-nav-trigger').on('click', function(event) {
      event.preventDefault();
      $('body').toggleClass('js-nav-visible');
    });

    $('.js-anchor').on('click', function() {
      $('body').removeClass('js-nav-visible');
    });
  }
}

cbtl.modal = {
  init: function() {
    $('.js-modal-trigger').on('click', function(event) {
      var modalTarget = $(this).data('modal');
      event.preventDefault();
      showModal(modalTarget);
    });

    $('.modal__overlay, .js-modal-hide').on('click', function(event) {
      if ($(event.target).is(this)) {
        hideModal();
      }
    });

    function showModal(target) {
      $('#video-modal').addClass('js-modal-shown');
      var modalTarget = '#' + target;
      $('body').addClass('js-modal-visible');
      $(modalTarget).addClass('js-modal-shown');
    }

    function hideModal() {
      $('body').removeClass('js-modal-visible');
      $('.js-modal-shown').removeClass('js-modal-shown');
    }
  }
}

cbtl.videoControls = {
  init: function() {

    var video = $('#intro-video')[0];
    $('.modal__overlay, .js-modal-hide').on('click', function(event) {
      if ($(event.target).is(this)) {
        video.pause();
      }
    });

    $('#intro-video').on('click', function() {
      if (this.paused) {
        this.play();
      } else {
        this.pause();
      }
    });
  }
}

cbtl.scrollToHow = {
  init: function() {
    var trigger = $('#how'),
        distance = trigger.offset().top;

    trigger.on('click', function(event) {
      $('html, body').animate({
        scrollTop: distance
      }, 300);
    })
  }
}

cbtl.togglePass = {
  init: function() {
    var trigger = $('#js-show-pass'),
        target = $('.js-password-container'),
        hidePass = $('#js-hide-pass');

    trigger.on('click', 'a', function(event) {
      event.preventDefault();
      target.removeClass('js-hidden');
      trigger.addClass('js-hidden');
    });

    hidePass.on('click', 'a', function(event) {
      event.preventDefault();
      target.addClass('js-hidden');
      trigger.removeClass('js-hidden');
    });
  }
}

cbtl.animatePhones = {
  init: function() {
    var ctrl = new ScrollMagic.Controller({
      globalSceneOptions: {
        triggerHook: 'onCenter'
      }
    });

    $('.js-animate-phone').each(function() {

      new ScrollMagic.Scene({
        triggerElement: this
      })
      .setClassToggle(this, 'js-animated')
      .addTo(ctrl);
    });
  }
}

cbtl.animatePoints = {
  init: function() {
    var points = $('#total-points');

    points.prop('Counter', 0).animate({
      Counter: points.text()
    }, {
      duration: 2000,
      easing: 'swing',
      step: function(now) {
        points.text(Math.ceil(now));
      }
    });
  }
}

cbtl.dob = {
  init: function() {
    var formattedDob = $('#birthday').val(),
      dobArray = [],
      dobArray = formattedDob.split('-'),
      month = $('#month'),
      day = $('#day'),
      year = $('#year');

    month.val(dobArray[1]);
    day.val(dobArray[2]);
    year.val(dobArray[0]);
  }
}

cbtl.setDob = {
  init: function() {
    var dobField = $('#birthday'),
      month = $('#month'),
      day = $('#day'),
      year = $('#year'),
      dob;

    $('.js-dob-field').on('change paste keyup', function() {
      dob = year.val() + '-' + month.val() + '-' + day.val();
      dobField.val(dob);
    });
  }
}



cbtl.updatePassword = {
  init: function() {
    var passField = $('#current-password'),
        newPass = $('#new-password'),
        confirmPass = $('#confirm-password');

    passField.on('change paste keyup', function() {
      if (passField.val() != '') {
        newPass.prop('disabled', false);
        confirmPass.prop('disabled', false);
      } else {
        newPass.prop('disabled', true);
        confirmPass.prop('disabled', true);
        newPass.val('');
        confirmPass.val('');
      }
    });
  }
}

cbtl.selectCardImg = {
  init: function() {
    var designField = $('#design_id');

    $('.card__designs__image').on('click', function() {
      var selected = $(this),
          selectedDesign = selected.data('design');

      $('.selected-design').removeClass('selected-design');
      selected.addClass('selected-design');
      designField.val(selectedDesign);
    });
  }
}

cbtl.validateDate = {
  init: () => {
    $("#datepicker").datepicker({ minDate: -30, maxDate: -1 });
  }
}

cbtl.openApp = {
  init: function() {
    var isIOS = (navigator.userAgent.match(/(iPad|iPhone|iPod)/i) ? true : false ),
        isAndroid = (navigator.userAgent.match(/(Android)/i) ? true : false ),
        iOSUri = 'coffeebean://',
        androidUri = 'intent://open/#Intent;scheme=coffeebean;package=com.coffeebean.apps.production;end',
        fallbackUri = '/customers/account/',
        fallbackTimeout = 3500;

    if (isIOS || isAndroid) {
      var appLaunchUri = (isIOS) ? iOSUri : androidUri;
      window.open(appLaunchUri, '_self');
      setTimeout(function() {
        window.location = fallbackUri;
      }, fallbackTimeout);
    } else {
      window.location = fallbackUri;
    }
  }
}

$(function() {
  cbtl.mobileNav.init();
  cbtl.modal.init();
});
